<template>
  <section class="newContanier">
    <h1 id="title">
      {{ newsObj.title }}</h1>
    <p id="time">
      {{ newsObj.releaseDate }}</p>
    <div
      id="news"
      v-html="newsObj.content"
    />
    <div
      class="source-wrap "
      style="width: 1200px;position: relative"
    >
      <p class="source">
        来源：<span>{{ newsObj.source }}</span>
      </p>
      <p class="sourceUrl">
        来源地址：<span id="sourceUrl">{{ newsObj.sourceUrl }}</span>
      </p>
    </div>
  </section>
</template>

<script>
import { getNewsInfo } from '@/api/website/website'

export default {
  data() {
    return {
      t: Number(this.$route.query.t),
      id: Number(this.$route.query.id),
      source: this.$route.query.s,
      sourceUrl: this.$route.query.u,
      loading: false,
      newsObj: {
        title: '',
        Introduction: '',
        firstImageUrl: '',
        source: '',
        sourceUrl: '',
        releaseDate: '',
        content: '',
      },
    }
  },
  created() {
    if (this.id) {
      this.getContent()
    } else {
      this.newsObj.content = localStorage.getItem('content')
      this.newsObj.releaseDate = localStorage.getItem('releaseDate')
      this.newsObj.title = localStorage.getItem('title')
      this.newsObj.source = this.source
      this.newsObj.sourceUrl = this.sourceUrl
    }
  },
  methods: {
    getContent() {
      getNewsInfo(this.id).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.newsObj = { ...resData.data }
        }
      })
    },
  },
}
</script>

<style scoped>
.newContanier {
  padding-top: 20px;
  width: 1200px;
  font-size: 16px;
  line-height: 30px;
  margin: 0 auto;
}
#title {
  font-size: 20px;
  margin: 20px 0;
  text-align: center;
  color: #000;
  font-weight: bold !important;
}
#time {
  font-size: 12px;
  text-align: right;
  margin: 20px 40px;
  line-height: 20px;
}
#news p, #news section {
  text-indent: 2em;
}
#news p img{
  width: 50%;
  margin: 20px auto;
}
.pic-center{
  text-align: center;
}
.source-wrap{
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 12px;
  color: #51587B;
  font-weight: normal;
}
.flex-space-between{
  display: flex;
  justify-content: space-between;
}
.news-loading{
  width: 100%;
  text-align: center;
  display: none;
}
.source,
.sourceUrl{
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sourceUrl{
  text-align: right;
}
</style>
